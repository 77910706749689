import React, { Suspense, useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Header from '../components/Header'
import HeaderWarning from '../components/Header/HeaderWarning'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import BetaWarning from '../components/BetaWarning'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity
} from './AddLiquidity/redirects'
import Pool from './Pool'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
import Teleport from './Teleport'
import { RedirectToTeleport } from './Teleport/redirects'
import IDO from './IDO'
import { IDODetail } from './IDO/IDODetail'
import MaintainPage from './Maintain'
import NotFoundPage from './404'
import { tagManagerArgs } from '../constants'
import TagManager from 'react-gtm-module'
import Footer from '../components/Footer'
import VaspModal from '../components/VaspModal'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 60px;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 16px;
    padding-top: 2rem;
  `};

  z-index: 1;
`

const Marginer = styled.div`
  margin-top: 4rem;
`

export default function App() {
  const history = useHistory()

  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  }, [])

  useEffect(() => {
    console.info('%cBloctoSwap 2.0.0(β)%c by portto', 'color: #3373a1;font-size: 3em;', 'color: inhert;font-size: 1em;')
    fetch('https://api.blocto.app/blockchainInfo')
      .then(response => response.json())
      .then(({ blockchains }) => {
        const hasMaintain = blockchains.some((blockchain: any) => blockchain.is_under_maintenance)
        if (hasMaintain) {
          history.push('/maintain')
        }
      })
  }, [history])

  return (
    <Suspense fallback={null}>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      <AppWrapper>
        <VaspModal />
        <HeaderWarning />
        <HeaderWrapper>
          <Switch>
            <Route exact strict path="/teleport" render={() => <Header isTeleport />} />
            <Route component={Header} />
          </Switch>
        </HeaderWrapper>
        <BodyWrapper>
          <Popups />
          <Web3ReactManager>
            <Switch>
              <Route exact strict path="/maintain" component={MaintainPage} />
              <Route exact strict path="/swap" component={Swap} />
              <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
              <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
              <Route exact strict path="/pool" component={Pool} />
              <Route exact strict path="/teleport" component={Teleport} />
              <Route exact strict path="/teleport/:outputCurrency" component={RedirectToTeleport} />
              <Route exact strict path="/create" component={RedirectToAddLiquidity} />
              <Route exact path="/add" component={AddLiquidity} />
              <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
              <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
              <Route exact path="/create" component={AddLiquidity} />
              <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
              <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
              <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
              <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
              <Route exact strict path="/ido" component={IDO} />
              <Route exact strict path="/ido/:name" component={IDODetail} />
              <Route exact strict path="/" component={Swap} />
              <Route path="*" component={NotFoundPage} />
            </Switch>
          </Web3ReactManager>
          <Switch>
            <Route exact path="/pool" render={() => <BetaWarning fullWidth />} />
            <Route path="*" render={() => <BetaWarning />} />
          </Switch>
          <Marginer />
        </BodyWrapper>
      </AppWrapper>
      <Footer />
    </Suspense>
  )
}
