import React, { useState, useEffect } from 'react'
import Modal from '../Modal'
import styled from 'styled-components'
import CheckIcon from '../../assets/icon/status/check-white.svg'
import MainButton from '../../components/Common/MainButton'
import ReactGA from 'react-ga'

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IPGeolocationInfo {
  cityName: string
  continent: string
  continentCode: string
  countryCode: string
  countryName: string
  currency: {
    code: string
    name: string
  }
  ipAddress: string
  ipVersion: number
  isProxy: boolean
  language: string
  latitude: number
  longitude: number
  regionName: string
  timeZone: string
  timeZones: string[]
  tlds: string[]
  zipCode: string
}

const VASP_COUNTRIES = [
  'DZ', // Algeria
  'BD', // Bangladesh
  'BO', // Bolivia
  'CN', // China
  'CU', // Cuba
  'EG', // Egypt
  'GH', // Ghana
  'IR', // Iran
  'IQ', // Iraq
  'KW', // Kuwait
  'LY', // Libya
  'MK', // North Macedonia
  'MA', // Morocco
  'MM', // Myanmar
  'NP', // Nepal
  'KP', // North Korea
  'PK', // Pakistan
  'QA', // Qatar
  'RU', // Russia
  'SA', // Saudi Arabia
  'SL', // Sierra Leone
  'SY', // Syria
  'TW', // Taiwan
  'TN', // Tunisia
  'US', // United States
  'UM' // United States Minor Outlying Islands
]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  text-align: center;
  color: #232528;
`

const ScrollArea = styled.div`
  overflow: auto;
`

const Title = styled.p`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 20px;
`

const Content = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 20px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
  user-select: none;
`

const IconContainer = styled.div`
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border: 1.5px solid #e1e4e8;

  img {
    display: none;
  }
`

const Checkbox = styled.input`
  width: 0;
  height: 0;
  display: none;

  :checked + div {
    background: #0075ff;

    img {
      display: block;
    }
  }
`

export default function VaspModal() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    const hasReadVaspAlert = window.localStorage.getItem('BloctoSwap.hasReadVaspAlert')
    if (hasReadVaspAlert === 'true') return

    fetch('https://freeipapi.com/api/json/')
      .then(res => res.json())
      .then((result: IPGeolocationInfo) => {
        if (VASP_COUNTRIES.includes(result.countryCode.toUpperCase())) {
          setIsModalOpen(true)
        }
      })
      .catch(() => false)
  }, [])

  const handleButtonClick = () => {
    window.localStorage.setItem('BloctoSwap.hasReadVaspAlert', 'true')
    ReactGA.event({
      category: 'VASP',
      action: 'Confirm reading Important Regulatory Notice',
      label: 'swap_approve_vasp'
    })
    setIsModalOpen(false)
  }

  return (
    <Modal isOpen={isModalOpen} onDismiss={() => null} maxHeight={90}>
      <Container>
        <ScrollArea>
          <Title>Important Regulatory Notice</Title>
          <Content>
            We strongly recommend that all users consult legal counsel before using our products. As our products are
            not intended for use in the jurisdictions listed in the table below, which may be updated from time to time,
            users in those jurisdictions are advised not to use our products, and we are not responsible for any
            disruptions, damage, losses, and/or restrictions that may result from the use of our products in those
            jurisdictions:
            <br />
            <br />
            Algeria, Bangladesh, Bolivia, China, Cuba, Egypt, Ghana, Iran, Iraq, Kuwait, Libya, Morocco, Myanmar, Nepal,
            North Korea, North Macedonia, Pakistan, Qatar, Russia, Saudi Arabia, Sierra Leone, Syria, Taiwan, Tunisia,
            United States.
          </Content>
        </ScrollArea>
        <Label>
          <Checkbox type="checkbox" checked={isChecked} onChange={event => setIsChecked(event.target.checked)} />
          <IconContainer>
            <img src={CheckIcon} alt="Check icon" />
          </IconContainer>
          I have read and understood the notice. Don’t show this message again.
        </Label>
        <MainButton disabled={!isChecked} onClick={handleButtonClick}>
          Confirm
        </MainButton>
      </Container>
    </Modal>
  )
}
